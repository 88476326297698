export enum TestPaymentMethodsStripe {
    Successful_Visa = "4242 4242 4242 4242",
    Successful_Mastercard = "5555 5555 5555 4444",
    Successful_AmericanExpress = "3782 822463 10005",
    Successful_Discover = "6011 1111 1111 1117",
    Declined_GenericDecline = "4000 0000 0000 0002",
    Declined_InsufficientFunds = "4000 0000 0000 9995",
    Declined_LostCard = "4000 0000 0000 9987",
    Declined_StolenCard = "4000 0000 0000 9979",
}

export enum TestPaymentMethodsAuthNet {
    Successful_Visa = "4007000000027",
    Successful_Mastercard = "5424000000000015",
    Successful_AmericanExpress = "370000000000002",
    Successful_Discover = "6011000000000012",
}
